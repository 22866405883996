<template>
  <div class="box">
    <div class="top"></div>
    <div>
      <router-link to='/yyzz' class="colors">
        <div class="divb">营业执照</div>
      </router-link>
      <div class="diva"></div>
      <router-link to='/zgcgxjsqy' class="colors">
        <div class="divb">中关村高新技术企业</div>
      </router-link>
      <div class="diva"></div>
      <router-link to='/icp' class="colors">
        <div class="divb">增值电信业务经营许可证</div>
      </router-link>
      <div class="diva"></div>
      <router-link to='/zzq' class="colors">
        <div class="divb">计算机软件著作权登记证书</div>
      </router-link>
      <div class="diva"></div>
      <router-link to='/appba' class="colors">
        <div class="divb">斗转多开备案信息</div>
      </router-link>
      <div class="diva"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  props: {},
  data() {
    return {
      isLoading: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {},
};
</script>
<style>
.box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.box ul{
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box ul li{
  display:inline;
  list-style-type:none;
  padding-left: 0.4rem;
  height: 30vh;
  border-bottom: 1px solid rgba(7,17,27,0.1);
}
.diva {
  border-bottom: 1px solid #0000001a;
}
.divb{
  height: 57px;
  font-size: 17px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  justify-content: flex-start;
  align-items: center;
}
.colors{
  color: black;
}
.top{
  height: 1vh;
}
</style>